export const linkResolver = (link) => {

  switch (link) {

    case "home-page":
      return "/";

    case "demo-page":
      return "/demo";

    case "brand-story-page":
      return "/brand-story";

    case "visual-identity-page":
      return "/visual-identity";

    case "content-guidelines-page":
      return "/content-guidelines";

    case "component-library-page":
      return "/component-library";

    case "showcase-page":
      return "/showcase";

    case "contact-page":
      return "/contact";

    case "support-page":
      return "/support";

    case "about-page":
      return "/about";

    case "careers-page":
      return "/careers";

    case "pricing-page":
      return "/pricing";

    default:
      return "/";
  }
}